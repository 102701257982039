import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start

export const ChatWidget = require("../../../components/src/HappyFoxChat.js")

export interface AllVideosArrayType {
  videoURL: string
  videoName: string
  thumbnail: string | undefined
  videoDesc: string
}


export interface Testimonial {
  video: TestimonialVideo
}

export interface TestimonialVideo {
  data: TestimonialVideoData[]
}

export interface TestimonialVideoData {
  id: string
  type: string
  attributes: TestimonialAttributes
}

export interface TestimonialAttributes {
  video_name: string
  description: string
  priority: number
  video_file: VideoFile
  thumbnail_image?: ThumbnailImage
  video_type: string
}

export interface VideoFile {
  content_type: string
  url: string
}

export interface ThumbnailImage {
  id: number
  content_type: string
  url: string
}

export interface AllVideosArray {
  id: string
  videoURL: string
  videoName: string
  videoType: string
  videoDesc: string
  priority: number
  thumbnail: string
}
interface VideoData {
  title: string | undefined;
  programName: string | undefined;
  programVideo: string | undefined;
}

import React, { RefObject, createRef } from "react"
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {

  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;

  videoPlayedValue: any;
  introVideoURL: any;
  grade: any;
  program: any;
  accreditation: any;

  openD: any;
  openOrientation: any;
  mopen: any;
  dopen: any;
  dopenOne: any;
  drawerWidth: any;
  gradeData: any;
  programData: any;
  accreditaionData: any;
  currentVideoTime: any;
  totalVideoTime: any;
  calculateErrors: any;
  openverify: boolean;
  openverifyVideo: boolean;

  recommVideoURL: any;
  userData: any;
  testimonial: any;
  introVideoData: any;
  postIntroVideoData: any;
  isLoading: boolean;
  onDashboardPage: boolean;
  allVideos: any;
  allVideos_introVideoID: any;
  allVideos_introVideoURL: any;
  allVideos_homeSchoolerVideoURL: any;
  allVideos_homeSchoolerVideoName: any;
  allVideos_homeSchoolerVideoDes: any;
  allVideosArray:any,
  selectedTestimonialVideo: {
    videoURL: string,
    videoName: string,
    videoDesc: string,
  }
  allVideos_orientationVideoURL: any;
  snackbarOpen: any;
  snackbarOpenFalse: any;
  msg: any;
  allVideo_types: any;
  testVideosData: any;
  allVideosLoader: any;

  loaderResendLink: boolean;
  openModalProgramVideo: boolean;
  selectedProgramTitle: string;
  age: string;
  allVideosResponse: any;
  hgFormula_PursueWhatMatters: any;
  hgFormula_TheHgFormula: any;
  hgFormula_MakingTheChoice: any;
  hgFormula_ExplorePrograms: any;
  hgFormula_AboutHg: any;
  gradeApiData: any;
  programApiData: any;
  accreditationApiData: any;
  feesProgramApiData: any;
  toggleFeeCaculator: boolean,
  firstDescription: string[]
  secondDescription: string[]

  getUserInfoResponseApi: any;
  getHgFormulaResponseApi: any;
  getIntroVideoTimeResponseApi: any;
  apiPostIntroVideoTimeResponseApi: any;
  getGradeCalculatorResponseApi: any;
  getProgramCalculatorResponseApi: any;
  accreditationCalculatorPageResponseApi: any;
  views:boolean,
  isStateExpandeds:boolean,
  feesProgramCalculatorResponseApi: any;
  ticketErrorsDaylife: any;
  open: boolean;
  videoData: VideoData | null;
  download: string;
  timer: number
  isInView: boolean
  hgLoading: boolean,
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";

  apiVideoCallId: string = "";
  apiAllVideoCallId: string = "";
  testimonialVideoAPI: string = '';

  apiCalacCallId: string = "";
  apiPostIntroVideoCallId: string = "";
  apiGetIntroVideoTimeCallId: string = "";

  apiHgFormulaCallId: string = "";
  apiUserCallId: string = "";
  apiGradeCallId: string = "";
  apiProgramCallId: string = "";
  apiAccreditationCallId: string = "";
  apiFeeProgramCallId: string = "";
  videoRef: RefObject<HTMLDivElement>;
  observer: IntersectionObserver | null;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.videoRef = createRef<HTMLDivElement>();
    this.observer = null;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,

      videoPlayedValue: 0,
      introVideoURL: "",
      grade: "choose",
      program: "choose",
      accreditation: "choose",
      openD: false,
      openOrientation: true,
      mopen: true,
      dopen: false,
      dopenOne: false,
      drawerWidth: 300,
      gradeData: [],
      programData: [],
      accreditaionData: [],
      currentVideoTime: 0,
      totalVideoTime: 0,
      calculateErrors: {},
      openverify: false,
      openverifyVideo: false,
      recommVideoURL: "",
      views:false,
      isStateExpandeds:false,
      userData: { id: "", fullName: "", isActive: "" },
      testimonial: {
        one: false,
        two: true,
        three: true,
        four: true,
        five: true
      },
      introVideoData: { id: "", introVideoURL: "" },
      postIntroVideoData: { id: "", watchTime: 0, isCompleted: false },
      isLoading: false,
      onDashboardPage: false,
      allVideos: [],
      allVideos_introVideoID: "",
      allVideos_introVideoURL: "",
      allVideos_homeSchoolerVideoURL: "",
      allVideos_homeSchoolerVideoName: "",
      allVideos_homeSchoolerVideoDes:"",
      allVideosArray:[],
      selectedTestimonialVideo: {
        videoURL: '',
        videoName: '',
        videoDesc: ''
      },
      allVideos_orientationVideoURL: "",
      snackbarOpen: false,
      snackbarOpenFalse: false,
      msg: "",
      allVideo_types: [
        "onboarding",
        "orientation",
        "testimonial",
        "homeschooler"
      ],
      testVideosData: [],
      allVideosLoader: true,

      loaderResendLink: false,
      openModalProgramVideo: false,
      selectedProgramTitle: "",
      age: "",
      allVideosResponse: {},
      hgFormula_PursueWhatMatters: [],
      hgFormula_TheHgFormula: [],
      hgFormula_MakingTheChoice: [],
      hgFormula_ExplorePrograms: [],
      hgFormula_AboutHg: [],
      gradeApiData: [],
      programApiData: [],
      accreditationApiData: [],
      feesProgramApiData: [],
      toggleFeeCaculator: false,
      firstDescription: [],
      secondDescription: [],

      //test cases responses
      getUserInfoResponseApi: {},
      getHgFormulaResponseApi: {},
      getIntroVideoTimeResponseApi: {},
      apiPostIntroVideoTimeResponseApi: {},
      getGradeCalculatorResponseApi: {},
      getProgramCalculatorResponseApi: {},
      accreditationCalculatorPageResponseApi: {},
      feesProgramCalculatorResponseApi: {},
      ticketErrorsDaylife: {},
      open: false,
      timer: 0.01,
      videoData: { programName: '', programVideo: '', title: '' },
       download:'',
       isInView: false,
       hgLoading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    this.getAllVideos();
    this.getHgFormulaData();
    this.getUserInfo();
    this.gradeCalculatorPage();
    this.programCalculatorPage();
    this.accreditationCalculatorPage();
    this.scroolBasedOnLink();
    this.getTestimonialVideos();
    this.setState({ hgLoading: true })
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.setState({ isInView: true });
          } else {
            this.setState({ isInView: false });
          }
        });
      },
      { threshold: 0.5 }
    );

    if (this.videoRef.current) {
      this.observer.observe(this.videoRef.current);
    }

    ChatWidget.loadChatWidget();
  }

  async componentWillUnmount() {
    if (this.observer && this.videoRef.current) {
      this.observer.unobserve(this.videoRef.current);
    }
  }


  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  //api calls start
  getAllVideos = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAllVideoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiAllVideoCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getHgFormulaData = () => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiHgFormulaCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.hgFormulaUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getIntroVideoTime = () => {
    this.setState({ isLoading: false })
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const httpBody = {
      video_detail: {
        // video_id: "8",
        video_id: this.state?.allVideos_introVideoID
      },

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetIntroVideoTimeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiGetIntroVideoTimeCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetIntroVideoTimeCallMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  postVideoApi = (videoCurrentTime: any, watchComplete: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const httpBody = {
      video_detail: {
        video_id: this.state.allVideos_introVideoID,
        watch_time: videoCurrentTime,
        is_completed: watchComplete
      }

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostIntroVideoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiPostIntroVideoCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostIntroVideoCallMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getUserInfo = () => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiUserCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  gradeCalculatorPage = () => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      // "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGradeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.calcGradePage
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  programCalculatorPage = () => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      // "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProgramCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.calcProgramPage
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  accreditationCalculatorPage = () => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      // "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAccreditationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.calcAccreditionPage
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  feesProgramCalculatorPage = (gradeId: number, accreditationId: number, programId: number) => {

    const header = {
      "Content-Type": configJSON.HgFormulaContentType,
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFeeProgramCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.feesProgram + `?grade_id=${gradeId}&accreditation_id=${accreditationId}&program_id=${programId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getTestimonialVideos = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.testimonialVideoAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.testimonialAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //api calls end

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    if (apiRequestCallId && apiResponse) {
      switch (apiRequestCallId) {
        case this.apiAllVideoCallId:
          this.getAllVideosApiResponse(apiResponse);
          break;
        case this.testimonialVideoAPI:
          this.setTestimonialData(apiResponse);
          break;
        case this.apiHgFormulaCallId:
          this.getHgFormulaDataApiResponse(apiResponse);
          break;
        case this.apiUserCallId:
          this.getUserInfoApiResponse(apiResponse);
          break;
        case this.apiGetIntroVideoTimeCallId:
          this.getGetIntroVideoIdApiResponse(apiResponse);
          break;
        case this.apiPostIntroVideoCallId:
          this.postIntroVideoDetailsApiResponse(apiResponse);
          break;
        case this.apiGradeCallId:
          this.getGradeCalculatorPageApiResponse(apiResponse);
          break;
        case this.apiProgramCallId:
          this.getProgramCalculatorPageApiResponse(apiResponse);
          break;
        case this.apiAccreditationCallId:
          this.getAccreditationApiResponse(apiResponse);
          break;
        case this.apiFeeProgramCallId:
          this.getFeeCalculatorApiResponse(apiResponse);
          break;
      }
    }
  }

  //Api response functions

  getAllVideosApiResponse(apiResponse: any) {
    this.setState({ allVideosLoader: true, allVideosResponse: apiResponse });
    if (apiResponse?.video.data) {
      this.setState({ allVideosLoader: false });
      apiResponse?.video.data?.map((item: any) => {
        this.state.allVideos.push({
          id: item?.id,
          videoURL: item?.attributes?.video_file?.url,
          videoName: item?.attributes?.video_name,
          videoType: item?.attributes?.video_type,
          videoDesc: item?.attributes?.description,
          priority: item?.attributes?.priority,
          thumbnail: item?.attributes?.thumbnail_image?.url,
        });
      });
      
      this.state.allVideos?.map((item: any) => {
        if (item?.videoType == this.state.allVideo_types[0]) {
          this.setState({ allVideos_introVideoID: item?.id, allVideos_introVideoURL: item?.videoURL },()=>{this.getIntroVideoTime()})
        }
        if (item.videoType == this.state.allVideo_types[3]) {
          this.setState({ allVideos_homeSchoolerVideoURL: item.videoURL,  allVideos_homeSchoolerVideoName: item.videoName, allVideos_homeSchoolerVideoDes: item.videoDesc })
          

        }
        if (item?.videoType == this.state.allVideo_types[1]) {
          this.setState({ allVideos_orientationVideoURL: item?.videoURL, isLoading: true });
        }
      });
      
      let testVidoes1 = this.state.allVideos?.filter(
        (data: any) => data.videoType == this.state.allVideo_types[3]
      );
      let testVidoes = testVidoes1?.filter(
        (data: any) => data.priority !== null
      );
      testVidoes.sort(function (a: any, b: any) {
        return a.priority - b.priority;
      });
      this.setState({ testVideosData: testVidoes });
    } else {
      this.setState({ allVideosLoader: false });
    }
    
  }

  setTestimonialData = (responseJson: Testimonial) => {
    let allVideosArray: AllVideosArrayType[] = [];

    if (responseJson.video.data) {
      responseJson.video.data.forEach((item: TestimonialVideoData) => {
        allVideosArray.push({
          videoURL: item.attributes.video_file.url,
          videoName: item.attributes.video_name,
          thumbnail: item.attributes.thumbnail_image?.url,
          videoDesc: item.attributes.description,
        });
      });
    }

    this.setState({ allVideosArray, selectedTestimonialVideo: allVideosArray[0] });
  }

  getHgFormulaDataApiResponse(apiResponse: any) {
    this.setState({ getHgFormulaResponseApi: apiResponse, hgLoading: false });
    if (apiResponse?.data) {
      this.setState({ hgFormula_PursueWhatMatters: apiResponse?.data[0]?.attributes })
      this.setState({ hgFormula_TheHgFormula: apiResponse?.data[1]?.attributes })
      this.setState({ hgFormula_MakingTheChoice: apiResponse?.data[2]?.attributes })
      this.setState({ hgFormula_ExplorePrograms: apiResponse?.data[3]?.attributes })
      this.setState({ hgFormula_AboutHg: apiResponse?.data[4]?.attributes })

      const parts = apiResponse?.data[0]?.attributes.description.split(" ");
      const parts2 = apiResponse?.data[0]?.attributes.hg_formula_sections?.data[0]?.attributes?.description.split(" ")
      this.setState({firstDescription: parts, secondDescription: parts2})
    }
  }

  getUserInfoApiResponse(apiResponse: any) {
    this.setState({ getUserInfoResponseApi: apiResponse });
    if (apiResponse?.data) {
      this.setState({
        userData: {
          ...this.state.userData,
          id: apiResponse.data?.id,
          fullName: apiResponse.data?.attributes?.first_name,
          isActive: apiResponse.data?.attributes?.activated,
        }
      })

      localStorage.setItem('username', String(apiResponse.data?.attributes?.first_name + " " + apiResponse.data?.attributes?.last_name))
    }

  }
  getGetIntroVideoIdApiResponse(apiResponse: any) {
    this.setState({ getIntroVideoTimeResponseApi: apiResponse });
    if (apiResponse?.data) {
      this.setState({
        postIntroVideoData: {
          ...this.state.postIntroVideoData,
          id: apiResponse?.data.attributes?.video_id,
          watchTime: apiResponse?.data.attributes?.watch_time,
          isCompleted: apiResponse?.data.attributes?.is_completed
        }
      }, () => {
        if (this.state.postIntroVideoData.watchTime == 0) {
          this.setState({ timer: 0.1, isLoading: true })
        } else {
          this.setState({isLoading: true})
          if (!this.state.postIntroVideoData.isCompleted) {
            this.setState({ timer: this.state.postIntroVideoData.watchTime })
          } else {
            this.setState({ timer: 0.1 })
          }
        }
        setStorageData('curentTime', this.state.postIntroVideoData.watchTime)
        setStorageData('complete', this.state.postIntroVideoData.isCompleted)
      })
    }
   
  }

  postIntroVideoDetailsApiResponse(apiResponse: any) {
    this.setState({ apiPostIntroVideoTimeResponseApi: apiResponse })
    if (apiResponse?.data) {
      localStorage.setItem('curentTime', String(apiResponse?.data.attributes?.watch_time))
      localStorage.setItem('complete', String(apiResponse?.data.attributes?.is_completed))
      if (apiResponse?.data.attributes?.is_completed) {
        this.props.navigation.navigate('DashBoardDayInLifeVideo')
      }
      else {

        this.props.navigation.navigate('DashBoardNonExpandablePage')

      }

    } else if (apiResponse.message == "User already watched") {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "DashBoardDayInLifeVideo");
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg);
    }
   
  }

  getGradeCalculatorPageApiResponse(apiResponse: any) {
    this.setState({ getGradeCalculatorResponseApi: apiResponse })
    if (apiResponse?.data) {
      this.setState({ gradeApiData: apiResponse?.data })
    }
    
  }

  getProgramCalculatorPageApiResponse(apiResponse: any) {
    this.setState({ getProgramCalculatorResponseApi: apiResponse })
    if (apiResponse?.data) {
      this.setState({ programApiData: apiResponse?.data })
    }
   
  }

  getAccreditationApiResponse(apiResponse: any) {
    this.setState({ accreditationCalculatorPageResponseApi: apiResponse })
    if (apiResponse?.data) {
      this.setState({ accreditationApiData: apiResponse?.data })
    }
   
  }

  getFeeCalculatorApiResponse(apiResponse: any) {
    this.setState({ feesProgramCalculatorResponseApi: apiResponse })
    if (apiResponse?.data) {
      this.setState({ feesProgramApiData: apiResponse?.data })
    } else if (apiResponse.message) {
      this.setState({ feesProgramApiData: apiResponse?.message })
    }
   
  }



  //Web file functions

  handleWatchLater = (videoCurrentTime: any, watchComplete: any) => {
    localStorage.setItem("curentTime", String(videoCurrentTime));
    localStorage.setItem("complete", String(watchComplete));
    this.postVideoApi(videoCurrentTime, watchComplete)

  };

  getHgFormulaSectionImage = (sectionId: number, imageId: number) => {
    switch (imageId) {
      case 1:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[sectionId].attributes?.image1
      case 2:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[sectionId].attributes?.image2
      case 3:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[sectionId].attributes?.image3
      case 4:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[sectionId].attributes?.image4
      case 5:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[sectionId].attributes?.image5
      default:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[sectionId].attributes?.image1
    }
  }

  handleNext = async(videoCurrentTime: any, watchComplete: any) => {
    const isCompleted = await getStorageData("complete");
    if (!isCompleted) {
      localStorage.setItem("curentTime", String(videoCurrentTime));
      localStorage.setItem("complete", String(watchComplete));
    }
    this.postVideoApi(videoCurrentTime, isCompleted);
  };

  getHgFormulaSubTitle = (blockId: number) => {
    switch (blockId) {
      case 0:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[blockId].attributes?.sub_title
      case 1:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[blockId].attributes?.sub_title
      case 2:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[blockId].attributes?.sub_title
      default:
        break
    }
  }

  getHgFormulaDescription = (blockId: number) => {
    switch (blockId) {
      case 0:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[blockId].attributes?.description
      case 1:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[blockId].attributes?.description
      case 2:
        const htmlContent = this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[blockId].attributes?.description.replace(/\n/g, '<br/>');
        return htmlContent
      default:
        break
    }
  }

  getMakeMyChoiceTitle = (blockId: number) => {
    switch (blockId) {
      case 0:
        return this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[blockId].attributes?.title
      case 1:
        return this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[blockId].attributes?.title
      case 2:
        return this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[blockId].attributes?.title
      default:
        break
    }
  }

  getMakeMyChoiceDescription = (blockId: number) => {
    switch (blockId) {
      case 0:
        return this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[blockId].attributes?.description
      case 1:
        return this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[blockId].attributes?.description
      case 2:
        return this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[blockId].attributes?.description
      default:
        break
    }
  }

  getMakeMyChoiceImage = (blockId: number) => {
    switch (blockId) {
      case 0:
        return this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[blockId].attributes?.image1
      case 1:
        return this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[blockId].attributes?.image1
      case 2:
        return this.state.hgFormula_MakingTheChoice?.hg_formula_sections?.data[blockId].attributes?.image1
      default:
        break
    }
  }

  getExploreProgramBlockHeader = (blockId: number) => {
    switch (blockId) {
      case 0:
        return this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId].attributes?.title
      case 1:
        return this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId].attributes?.title
      case 2:
        return this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId].attributes?.title
      default:
        break
    }
  }

  getExploreProgramBlockDescription = (blockId: number) => {
    switch (blockId) {
      case 0:
        return this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId]?.attributes?.description
      case 1:
        return this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId].attributes?.description
      case 2:
        return this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId].attributes?.description
      default:
        break
    }
  }

  getExploreProgramBlockInternalData = (blockId: number) => {
    if (this.state.hgFormula_ExplorePrograms) {
      switch (blockId) {
        case 0:
          return this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId]?.attributes?.hg_formula_programs?.data
        case 1:
          return this.state?.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId]?.attributes?.hg_formula_programs?.data
        case 2:
          return this.state?.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId]?.attributes?.hg_formula_programs?.data
        default:
          break
      }
    }
  }

  getVideo = (blockId: number, childId: number) => {
    return {
      title: this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId]?.attributes?.title, programName: this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId]?.attributes?.hg_formula_programs?.data[childId]?.attributes?.program_name,
      programVideo: this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId]?.attributes?.hg_formula_programs?.data[childId]?.attributes?.program_video
    }
  }

  handleModelOpen = (blockId: number, childId: number) => {
    const videoData = this.getVideo(blockId, childId);
    this.setState({ open: true, videoData })
  }

  getExploreProgramBottomContent = () => {
    return this.state?.hgFormula_ExplorePrograms?.hg_formula_quote?.data?.attributes
  }

  getExploreProgramBookData = (blockId: number) => {
    if (this.state.hgFormula_ExplorePrograms) {
      switch (blockId) {
        case 3:
          return this.state?.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId]?.attributes
        case 4:
          return this.state?.hgFormula_ExplorePrograms?.hg_formula_sections?.data[blockId]?.attributes
        default:
          break
      }
    }
  }

  getHgPartner = (blockId: number) => {
    if (this.state.hgFormula_AboutHg) {
      switch (blockId) {
        case 0:
          return this.state?.hgFormula_AboutHg?.hg_formula_sections?.data[blockId]?.attributes
        case 1:
          return this.state?.hgFormula_AboutHg?.hg_formula_sections?.data[blockId]?.attributes
        case 2:
          return this.state?.hgFormula_AboutHg?.hg_formula_sections?.data[blockId]?.attributes
        case 3:
          return this.state?.hgFormula_AboutHg?.hg_formula_sections?.data[blockId]?.attributes
        case 4:
          return this.state?.hgFormula_AboutHg?.hg_formula_sections?.data[blockId]?.attributes
        case 5:
          return this.state?.hgFormula_AboutHg?.hg_formula_sections?.data[blockId]?.attributes
        case 6:
          return this.state?.hgFormula_AboutHg?.hg_formula_sections?.data[blockId]?.attributes
        default:
          break
      }
    }
  }

  getAboutHgBottomContent = () => {
    return this.state?.hgFormula_AboutHg?.hg_formula_quote?.data?.attributes
  }

  scroolBasedOnLink = () => {
    const path: string = String(window.location)
    const link = path.split('#')[1]
    setTimeout(() => {
      const anchorElement = document.getElementById(link);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 2000);
  }

  gradeChangeHandler = (e: any) => {
    this.state.ticketErrorsDaylife['grade'] = "";
    this.setState({ grade: e.target.value })
  }

  programChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.state.ticketErrorsDaylife['program'] = "";
    this.setState({ program: e.target.value })
  }

  getHgFormulaTitle = (blockId: number) => {
    switch (blockId) {
      case 0:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[blockId].attributes?.title
      case 1:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[blockId].attributes?.title
      case 2:
        return this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[blockId].attributes?.title
      default:
        break
    }
  }

  accredationChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.state.ticketErrorsDaylife['accreditation'] = "";
    this.setState({ accreditation: e.target.value })
  }
  ticketValidateDaylife = () => {
    let isFormValid = true;
    if (this.state.grade === 'choose') {
      this.state.ticketErrorsDaylife['grade'] = "Required"
      isFormValid = false
    }
    if (this.state.accreditation === 'choose') {
      this.state.ticketErrorsDaylife['accreditation'] = "Required"
      isFormValid = false
    }
    if (this.state.program === 'choose') {
      this.state.ticketErrorsDaylife['program'] = "Required"
      isFormValid = false;
    }
    return isFormValid
  }
  hideErrorfive = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.ticketErrorsDaylife[event?.target.name] = '';
    this.setState({ ticketErrorsDaylife: this.state.ticketErrorsDaylife })
    
  }
  feeCalculatorHandler = () => {
    let isValidationPassed:any = this.ticketValidateDaylife();
    if (isValidationPassed) {
      this.feesProgramCalculatorPage(this.state.grade, this.state.accreditation, this.state.program)
      this.setState({ toggleFeeCaculator: true })
    }
    else {

      this.setState({
        ticketErrorsDaylife: this.state.ticketErrorsDaylife
      })
    
    }


  
}
  handleViewClicks=()=>{
    this.setState({views:!this.state.views})
    this.setState({isStateExpandeds:!this.state.isStateExpandeds})
  }
  handleNavigation = ()=>{
    let completed =  localStorage.getItem('complete')
    //istanbul ignore next
    if(completed==='true'){
      this.props.navigation.navigate('StudentEnrollmentFormStepper')
    }else{
      this.setState({open: true})
    }
  }

  
  handleModelCloseOnCross = () => {
    this.setState({
      open: false,
      videoData: { programName: '', programVideo: '', title: '' }
    })
  }

  proceedButton=()=>{
    this.setState({ open: false },()=>this.props.navigation.navigate('DashboardIntroVideo'))
  }
  openLink = (item:string) => {
    window.open(item, '_blank');
  }
  handlePersonalisedNavigate = () => {
    this.props.navigation.navigate('HomeschoolButtons')
  }

  selectTestimonialVideo = (item: AllVideosArray) => {
    this.setState({
      selectedTestimonialVideo: {videoURL: item.videoURL, videoName: item.videoName, videoDesc: item.videoDesc}
    })
  }
}




// Customizable Area End
